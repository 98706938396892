<template>
  <div>
    <div>
      <v-list>
        <!-- WEBSITE LOGO -->
        <v-list-item>
          <v-list-item-content
            v-model="groupOpened"
            class="website-infogroupOpened"
          >
            <div>
              <v-img
                v-if="tenant && tenant.websiteLogo"
                :src="tenant.websiteLogo"
              />
            </div>

            <!-- <v-list-item-subtitle>admin@ntsal.com</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav dense>
        <div v-for="(item, i) in items" :key="i">
          <v-list-group
            v-if="item.items"
            :prepend-icon="item.icon"
            no-action
            link
          >
            <!-- MAIN GROUP -->
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  class="subtitle-2"
                  v-text="item.text"
                  @click="changeGroup"
                />
              </v-list-item-content>
            </template>

            <v-text-field v-model="search" placeholder="search"></v-text-field>

            <div
              v-for="(subgroup, i) in search == ''
                ? item.items
                : filterAndSearch(item.items)"
              :key="i"
            >
              <!-- INNER GROUPS -->
              <NavbarGroup :group="subgroup" />

              <v-list-item v-if="subgroup.to" :to="subgroup.to" link>
                <v-list-item-icon>
                  <v-icon v-text="subgroup.icon"></v-icon>
                </v-list-item-icon>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-list-item-content v-on="on">
                      <v-list-item-title
                        class="capitalize"
                        v-text="subgroup.text"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <span>{{ subgroup.text }}</span>
                </v-tooltip>
              </v-list-item>
            </div>
          </v-list-group>

          <v-list-item v-else :to="item.to" link>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.text"
                class="subtitle-2"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import { settingNavBar } from "../modules/settings/navBar";
import { tenantNavBar } from "../modules/tenant/navBar";
import { postNavBar } from "../modules/posts/navBar";
import { contactUsNavBar } from "../modules/contactUs/navBar";
import {registerNavBar} from "../modules/register-form/navBar";
import { careersNavBar } from "../modules/careers/navBar";
import { seoNavBar } from "../modules/page/navBar";
import {subscribeNavbar} from "../modules/subscribe/navBar";
import { contentCategoryNavbar } from "../modules/settings/contentCategoryNavbar";
import { ticketNavBar } from "../modules/tickets/navbar";
import {usersNavBar} from "../modules/auth/navbar"
import { mapGetters } from "vuex";
import NavbarGroup from "./NavbarGroup.vue";

export default {
  computed: {
    ...mapGetters("contentManagement", [
      "menuCategory",
      "contentTypeWithMenuCategoryNull",
      "newlyAddedContentType",
    ]),
    ...mapGetters("contactUs", ["contactUsPaginated"]),
    ...mapGetters("registerForm" , ["registerFormPaginated"]),
    ...mapGetters("subscribe" , ["subscribes"]),
    ...mapGetters("auth", ["groups", "clientName", "roles"]),
    ...mapGetters("tenant", ["tenants", "tenant"]),
    ...mapGetters("page", ["pages"]),
    ...mapGetters("careers", ["careersPaginated"]),
  },
  data: () => ({
    search: null,
    selectedItem: 0,
    groupTenant: null,
    items: [],
    groupOpened: true,
    clientAdmin: false,
    contentManagmentItem: null,
    pageSize: 10,
    pageNumber: 1,
    currentTenant: null,
  }),
  async mounted() {
    this.groupTenant = (await this.groups)
      ? await this.groups
      : localStorage.getItem("tenantCode");
    this.roles.find((element) => {
      if (element.includes("client-admin")) {
        this.clientAdmin = true;
      }
    });
    this.roles.find((element) => {
      if (element.includes("super-admin")) {
        this.clientAdmin = true;
      }
    });
    await this.loadLanguages();
    await this.loadContentTypes();
    await this.getWebsiteLogo();
    await this.loadContactUs();
    await this.loadRegisterForm();
    await this.loadSubscribes();
    await this.loadCareers();
    await this.getNavbarList();
  },
  methods: {
    async getWebsiteLogo() {
      let tenantCode = localStorage.getItem("tenantCode");
      this.$store.dispatch("tenant/loadTenant", tenantCode);
    },
    filterAndSearch(list) {
      if (this.search == null) return list;
      let outerResult = list.filter((item) => {
        return item.text.toLowerCase().match(this.search);
      });
      let innerResult = [];
      list.forEach((item) => {
        let result = item.items?.filter((element) =>
          element.text.toLowerCase().match(this.search.toLowerCase())
        );
        if (result && result.length) innerResult.push(...result);
      });
      return [...innerResult, ...outerResult];
    },
    changeGroup() {
      this.groupOpened = true;
    },
    contentTypesWithNullCategory() {
      for (let item in this.contentTypeWithMenuCategoryNull) {
        if (this.contentTypeWithMenuCategoryNull[item].parentId == null) {
          this.contentManagmentItem.items.push({
            text:
              this.contentTypeWithMenuCategoryNull[item].menuTitle
                .charAt(0)
                .toUpperCase() +
              this.contentTypeWithMenuCategoryNull[item].menuTitle
                .slice(1)
                .toLowerCase(),
            to: `/content-type/${this.contentTypeWithMenuCategoryNull[item].id}`,
            object: this.contentTypeWithMenuCategoryNull[item],
          });
        }
      }
    },
    collapseSubItems() {
      this.nav.map((item) => (item.active = false));
    },
    async loadLanguages() {
      await this.$store.dispatch("contentManagement/loadLanguages");
    },
    async getNavbarList() {
      // show Cache menu only for super-admin and client-admin      
      let isClientAdmin = this.roles.includes("client-admin");
      let isSuperAdmin = this.roles.includes("super-admin");

      if (!isClientAdmin && !isSuperAdmin)
        settingNavBar[0].items = settingNavBar[0].items.filter(item => item.text !== "Caching");

      if (this.groups || localStorage.getItem("tenantCode")) {
        if (this.clientAdmin) {
          this.items.push(
            ...settingNavBar,
            ...postNavBar,
            ...contentCategoryNavbar,
            ...ticketNavBar,
            ...seoNavBar,
            ...usersNavBar
          );
          if (this.careersPaginated.result.length > 0) {
            this.items.push(...careersNavBar);
          }
          if (this.contactUsPaginated.result.length > 0) {
            this.items.push(...contactUsNavBar);
          }

          if (this.registerFormPaginated.result.length > 0) {
            this.items.push(...registerNavBar);
          }
          if (this.subscribes.length > 0) {
            this.items.push(...subscribeNavbar);
          }
          
        } else {
          this.items.push(
            ...postNavBar,
            ...seoNavBar,
            ...contentCategoryNavbar
          );
          if (this.contactUsPaginated.result.length > 0) {
            this.items.push(...contactUsNavBar);
          }
          if (this.careersPaginated.result.length > 0) {
            this.items.push(...careersNavBar);
          }
        }
        await this.loadMenuCategory();
        await this.loadPageCategory();
        await this.loadPages();
        await this.loadContentTypesWithMenuCategoryNull();
        await this.loadContentCategories();
        this.contentTypesWithNullCategory();
        await this.loadImageTypes();
      } else {
        await this.loadTenants();
        this.items.push(...tenantNavBar);
        this.tenantList();
      }
    },
    tenantList() {
      if (!this.groups) {
        this.items[0].items.push(
          {
            text: "Tenants",
            to: "/view-tenant",
          },
          {
            text: "Website S3",
            to: "/view-S3",
          },
          {
            text: "Mail sender",
            to: "/mail-sender",
          },
          {
            text: "Size Configuration",
            to: "/size-configuration",
          }
        );
      }
    },

    addNewContentTypeToNavbar(newlyAddedContentType) {
      this.contentManagmentItem.items.push({
        text:
          newlyAddedContentType.menuTitle.charAt(0).toUpperCase() +
          newlyAddedContentType.menuTitle.slice(1).toLowerCase(),
        to: `/content-type/${this.newlyAddedContentType.id}`,
        object: newlyAddedContentType,
      });
    },
    async loadContentTypes() {
      await this.$store.dispatch("contentManagement/loadContentTypes");
    },
    addContentToContentType() {
      const toRenderedMenuCategories = this.menuCategory.filter(
        (c) => c.parentId === null
      );
      this.contentManagmentItem = this.items.find(
        (item) => item.text == "Content Managment"
      );
      this.contentManagmentItem.items = [];
      toRenderedMenuCategories.forEach((group) =>
        this.constructMenuGroup(group)
      );
    },
    constructMenuGroup(group, items = []) {
      const category = this.menuCategory.find((c) => c.id === group.id);
      category.children?.map((child) => {
        items.push({
          id: child.id,
          text: child.name,
          items: [],
        });
      });
      category.contentTypeList?.map((contentType) => {
        items.push({
          text: contentType.menuTitle,
          to: `/content-type/${contentType.id}`,
          object: contentType,
        });
      });
      category.children?.forEach((child) => {
        this.constructMenuGroup(
          child,
          items.find((i) => i.id === child.id).items
        );
      });
      if (group.parentId === null) {
        this.contentManagmentItem.items.push({
          text: group.name,
          items: items,
        });
      }
    },
    async loadTenants() {
      await this.$store.dispatch("tenant/loadTenants");
    },
    async loadContentCategories() {
      await this.$store.dispatch("contentManagement/loadContentCategories");
    },
    async loadMenuCategory() {
      await this.$store.dispatch("contentManagement/loadMenuCategory");
    },
    async loadPageCategory() {
      await this.$store.dispatch("page/loadPageCategory");
    },
    async loadImageTypes() {
      await this.$store.dispatch("post/loadImageTypes");
    },
    async loadPages() {
      await this.$store.dispatch("page/loadPages");
    },
    async loadSubscribes() {
      await this.$store.dispatch("subscribe/loadSubscribes");
    },
    async loadContentTypesWithMenuCategoryNull() {
      await this.$store.dispatch(
        "contentManagement/loadContentTypesWithMenuCategoryNull"
      );
    },
    async loadCareers() {
      let payload = {
        sortingByList: [
          {
            fieldName: "id",
            direction: "DESC",
          },
        ],
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await this.$store.dispatch("careers/loadCareersPaginated", payload);
    },
    async loadContactUs() {
      let payload = {
        sortingByList: [
          {
            fieldName: "id",
            direction: "DESC",
          },
        ],
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await this.$store.dispatch("contactUs/loadContactUsPaginated", payload);
    },
    async loadRegisterForm() {
      let payload = {
        sortingByList: [
          {
            fieldName: "id",
            direction: "DESC",
          },
        ],
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await this.$store.dispatch("registerForm/loadRegesterFormPaginated", payload);
    },
  },
  watch: {
    menuCategory() {
      this.addContentToContentType();
      this.contentTypesWithNullCategory();
    },
    contentTypeWithMenuCategoryNull() {
      if (
        this.newlyAddedContentType != null &&
        !this.newlyAddedContentType.contentType
      ) {
        this.addNewContentTypeToNavbar(this.newlyAddedContentType);
      }
    },
  },
  components: { NavbarGroup },
};
</script>
<style scoped lang="scss">
::v-deep .v-image__image--cover {
  background-size: contain;
  width: 100%;
}
::v-deep .v-responsive__content {
  width: 0 !important;
}

.v-application .subtitle-2,
.v-application .text-h6 {
  font-family: $font-degular !important;
}
.logo-wraper {
  background: #f4f4f4;
  padding: 3%;
}
.capitalize {
  text-transform: capitalize;
}

.website-info {
  max-height: 6rem;
  padding-bottom: 0;
}

.list-inner-group {
  margin-left: 50px;
}
.container-items {
  color: red;
}
.contentTypes {
  text-decoration: none;
}
.v-text-field--outlined fieldset {
  color: white !important;
}
</style>

import RegisterForm from "../register-form/views/pages/registrationFormListing.vue"


export const registerFormRouters = [
  {
    path: "/register-form",
    name: "RegisterFormListing",
    component: RegisterForm,
  },
];

<template>
  <v-container fluid>
    <!-- DATATABLE -->
    <v-row>
      <v-col>
        <v-data-table
          hide-default-footer
          :headers="registerFormHeader"
          :loading="loading"
          :items-per-page="pageSize"
          :items="registerForm"
          class="capitalize"
        >
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Pagination -->
    <v-row
      class="justify-end mt-6 align-center"
      v-if="registerFormPaginated.totalNumberOfPages > 1"
    >
      <div class="mr-2">
        <v-select
          class="page-size-selector"
          label="page size"
          outlined
          dense
          hide-details
          :items="pageSizeOptions"
          :value="10"
          @change="pageSize = parseInt($event, 10)"
        />
      </div>
      <div class="mr-2">
        <span>{{ pageNumber }}</span>
        <span class="mx-1">From</span>
        <span>{{ registerFormPaginated.totalNumberOfPages }}</span>
      </div>
      <v-pagination
        class="align-self-start py-2"
        v-model="pageNumber"
        :length="registerFormPaginated.totalNumberOfPages"
      />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      snackbar: false,
      loading: false,
      pageSize: 10,
      pageNumber: 1,
      pageSizeOptions: [5, 10, 15, 20],
      registerFormHeader: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "select",
        },
        {
          text: "first name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        {
          text: "lastName",
          align: "start",
          sortable: false,
          value: "last name",
        },
        {
          text: "email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "mobile number",
          align: "start",
          sortable: false,
          value: "mobileNumber",
        },
            
        {
          text: "address",
          align: "start",
          sortable: false,
          value: "address",
        },
        {
          text: "country",
          align: "start",
          sortable: false,
          value: "country",
        },
        {
          text: "city",
          align: "start",
          sortable: false,
          value: "city",
        },
        {
          text: "company name",
          align: "start",
          sortable: false,
          value: "companyName",
        },
        {
          text: "job title",
          align: "start",
          sortable: false,
          value: "jobTitle",
        },
        {
          text: "know us",
          align: "start",
          sortable: false,
          value: "knowUs",
        },
        {
          text: "function",
          align: "start",
          sortable: false,
          value: "function",
        },
        {
          text: "reason",
          align: "start",
          sortable: false,
          value: "reason",
        },
        {
          text: "consent",
          align: "start",
          sortable: false,
          value: "consent",
        },

      ],
    };
  },
  async mounted() {
    this.loading = true;
    await this.loadRegisterForm();
    this.loading = false;
  },

  watch: {
    async pageNumber() {
      await this.loadRegisterForm();
      },
    async pageSize() {
      if (this.pageSize < 1 || this.pageSize > 20) {
        this.pageSize = 10;
      }
      await this.loadRegisterForm();

    },
  },

  computed: {
    ...mapGetters("registerForm" , ["registerFormPaginated"]),
    registerForm: {
      get() {
        return this.registerFormPaginated.result;
      },
    },
  },
  methods: {
    async loadRegisterForm() {
      let payload = {
        sortingByList: [
          {
            fieldName: "id",
            direction: "DESC",
          },
        ],
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
      };
      await this.$store.dispatch("registerForm/loadRegesterFormPaginated", payload);
    },
 
  },
};
</script>
<style lang="scss">
.contact-us-file {
  text-decoration: underline;
}
.text-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.page-size-selector {
  width: 75px;
}
.v-data-table {
  font-size: large;
  color: black;
}
</style>

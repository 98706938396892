import NetworkManager from "../../../network/network-manager";
import Parser from "../network/parser.js";

export default {
  async loadRegesterFormPaginated(context, payload) {
    await NetworkManager.post(
      context,
      `register-form/find-all-paginated`,
      payload,
      "PaginationResponse",
      "setPaginatedRegistration",
      Parser
    );
  }
}
